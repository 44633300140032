.owl-theme .owl-controls {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin-top: 10px;
}

.owl-theme .owl-controls .owl-nav [class*="owl-"] {
  color: #fff;
  cursor: pointer;
  background: #d6d6d6;
  border-radius: 3px;
  margin: 5px;
  padding: 4px 7px;
  font-size: 14px;
  display: inline-block;
}

.owl-theme .owl-controls .owl-nav [class*="owl-"]:hover {
  color: #fff;
  background: #869791;
  text-decoration: none;
}

.owl-theme .owl-controls .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-theme .owl-dots .owl-dot {
  zoom: 1;
  display: inline;
}

.owl-theme .owl-dots .owl-dot span {
  -webkit-backface-visibility: visible;
  -o-transition: opacity .2s ease;
  background: #d6d6d6;
  border-radius: 30px;
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  transition: opacity .2s;
  display: block;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}
/*# sourceMappingURL=index.56bcc9ab.css.map */
